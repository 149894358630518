.material-symbols-rounded {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}


.sidebar {
  background-color: rgb(52, 52, 52);
  height: 100%;
  ul {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    
    li {
      // list-style-type: none;
      color: rgb(199, 117, 150);
      a {
        color: inherit;
        padding: 15px 20px;
        display: flex;
        // align-items: center;
        text-decoration: none;

        .icon {
          margin-right: 15px;
        }

        .title {
          font-size: 1.2rem;
        }
      }

      a:hover {
        
      }
      
      a.active {
        
      }

      &:last-child {
        margin-top: auto;
      }

      &:hover {
        transition: all 250ms ease-in;
        background-color: rgb(143, 72, 99);
        color: rgb(196, 196, 196);
      }
      
      &.active {
        background-color: rgb(199, 117, 150);
        color: rgb(52, 52, 52);
      }
    }

  }
}